import React from 'react';

function PageHeader({ title, subtitle }) {
  return (
    <>
      <h1 className="title page-title">{title}</h1>
      {subtitle && <h2 className="subtitle page-subtitle">{subtitle}</h2>}
    </>
  );
}

export default PageHeader;
