import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Content, { HTMLContent } from '../components/Content';
import Testimonials from '../components/book/Testimonials';
import Seo from '../components/seo';
import PageHeader from '../components/PageHeader';

const BookPageTemplate = ({
  title,
  subtitle,
  content,
  testimonials,
  image,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-10">
          <PageHeader title={title} subtitle={subtitle} />
          <hr className="is-hidden-mobile" />
        </div>
      </div>
      <div className="is-hidden-tablet" style={{ marginBottom: '35px' }}>
        <Img
          className="full-width-image"
          alt={`${title} - ${subtitle}`}
          fluid={image.fluid}
        />
      </div>
      <div className="columns is-centered">
        <div className="column is-6">
          <PageContent className="content" content={content} />
        </div>
        <div className="column is-3 is-offset-1 is-hidden-mobile">
          <div className="image-shadow">
            <Img alt={`${title} - ${subtitle}`} fluid={image.fluid} />
          </div>
        </div>
      </div>
      <div className="columns is-centered is-multiline">
        <div className="column is-10">
          <h2 className="sidebar">Anmeldercitater</h2>
        </div>
        <Testimonials testimonials={testimonials} />
      </div>
    </>
  );
};

function BookPage({ data }) {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <BookPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.description}
        testimonials={post.frontmatter.testimonials}
        image={post.frontmatter.image.childImageSharp}
        content={post.html}
      />
    </Layout>
  );
}

export default BookPage;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        subtitle
        testimonials {
          author
          quote
        }
        image {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
