import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, title, url, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: 'da',
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      {description && <meta name="description" content={description} />}
      {description && <meta name="og:description" content={description} />}
      {image && (
        <meta name="image" content={`${site.siteMetadata.siteUrl}${image}`} />
      )}
      {image && (
        <meta
          name="og:image"
          content={`${site.siteMetadata.siteUrl}${image}`}
        />
      )}
      <meta name="og:title" content={title} />
      <meta name="og:type" content="article" />
      {url && (
        <meta name="og:url" content={`${site.siteMetadata.siteUrl}${url}`} />
      )}
    </Helmet>
  );
}

export default Seo;
