import React from 'react';

function Testimonials({ testimonials }) {
  return testimonials.map((data, index) => (
    <div key={index} className="column is-10">
      <div className="box">
        <p className="testimonial-quote">“{data.quote}”</p>
        <p className="testimonial-author">- {data.author}</p>
      </div>
    </div>
  ));
}

export default Testimonials;
